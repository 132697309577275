export const LogoSmall = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
        fill="#FF4700"
      />
      <path
        d="M28.8 9.6001H19.2C19.2 14.8961 14.896 19.2001 9.60004 19.2001V28.8C20.208 28.8 28.8 20.2081 28.8 9.6001Z"
        fill="black"
      />
      <path
        d="M38.4 19.2001V28.8C33.104 28.8 28.8 33.104 28.8 38.4H19.2C19.2 27.792 27.792 19.2001 38.4 19.2001Z"
        fill="black"
      />
    </svg>
  )
}
